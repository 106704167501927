.team-item {
  background: #003d5b;
  text-align: center;
  margin: 20px 0;
  padding: 50px 20px 40px 20px;
  border-radius: 8px 8px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.aboutUsContainer {
  margin: 0 auto;
}

.aboutUsDiv {
  margin: 0 auto;
  width: 85vw;
}

.team-item h3 {
  margin: 20px 0 1px 0 !important;
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

.cardContainer {
  display: flex;
}

.personalCard {
  padding: 40px;
  width: 100%;
}

.team-item:hover h3 {
  color: #ddd;
}

.team-info {
  display: block;
  margin-bottom: 0;
}

.team-info p {
  color: #acacac;
  font-style: italic;
}

.team-info::after {
  background: #008aff;
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 50px;
  height: 3px;
}

.team-img {
  padding: 6px 6px;
  background-color: #dedede;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 40%;
}

.linkedInClass {
  width: 30px;
}

.nameAndIcon {
  margin: 0 auto;
}

@media (max-width: 600px) {
  .cardContainer {
    display: flex;
    flex-direction: column;
    padding: 50px;
  }
}
