nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}

.navbar-menu-container {
  display: none;
}

.nav-logo-container {
  max-width: 300px;
}

.navbarTitle {
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin: 0.25rem 0.5rem;
  color: #ffff;
  cursor: pointer;
}

.navbarTitle img {
  border-radius: 30px;
}

@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }

  .navbar-links-container {
    display: none;
  }

  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }

  .navbar-menu-container {
    display: flex;
  }
}

.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
}

.navbar-cart-icon {
  font-size: 1.15rem;
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
