.privacy-container {
  width: 85vw;
  margin: 0 auto;
}

.privacy-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}

.privacy-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.privacy-primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: white;
  /* line-height: 5rem; */
  max-width: 600px;
}

.privacy-primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  color: white;
  margin: 1.5rem 0rem;
}

@media (max-width: 800px) {
  .privacy-banner-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .privacy-text-section {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .privacy-primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .privacy-primary-text {
    text-align: center;
  }
}
